<template>
  <div class="industry-finance-page-box">
    <!-- banner -->
    <img
      class="industry-finance-banner-img"
      src="@/assets/image/fs_banner.png"
      alt="banner"
    >
    <div class="content-box">
      <!-- 右边固定在边上的东西 -->
      <div class="contact-information-box">
        <el-popover placement="left" trigger="hover">
          <div class="finance-center-qr-code-box">
            <img
              src="@/assets/industryFinance/official_accounts.png"
              alt="image"
            >
            <span>扫码关注公众号</span>
          </div>
          <div slot="reference" class="icon-box">
            <span class="image qr" />
            <span class="font">二维码</span>
          </div>
        </el-popover>
        <el-popover placement="left" trigger="hover">
          <div class="finance-center-contact-information">
            <img src="@/assets/industryFinance/phone.png" alt="phone">
            <div class="phone-box">
              <span>咨询电话</span>
              <p>400 199 9369</p>
            </div>
          </div>
          <div slot="reference" class="icon-box">
            <span class="image service" />
            <span class="font">客服</span>
          </div>
        </el-popover>
      </div>
      <div class="center-box">
        <!-- 搜索框 -->
        <div class="search-box">
          <!-- 清除按钮 -->
          <span class="emptyButton" @click="emptyOut">全部清空</span>
          <div class="item box-type">
            <span class="label">搜索产品</span>
            <el-input v-model="formInline.productName" :maxlength="30" placeholder="请输入产品名称" @change="getdata">
              <i slot="prefix" class="icon el-input__icon el-icon-search" />
            </el-input>
          </div>
          <div class="item box-type">
            <span class="label">企业地址</span>
            <el-cascader
              v-model="formInline.cityArr"
              :options="options"
              placeholder="请选择省市"
              :props="{value:'code',label:'name'}"
              @change="handleChange"
            />
          </div>
          <div class="item gap">
            <span class="label">产品类型</span>
            <div class="option-box limited-height" :style="{height:isMore?'':'auto'}">
              <span
                v-for="(item, index) in typeArr"
                :key="index"
                :class="[activeIndex === index ? 'active' : '']"
                @click="seachProductType(item, index)"
              >{{ item.dictName }}</span>
              <span v-if="!isMore&&typeArr.length>3" style="border:0;" @click="isMore=true">收起<i class="icon el-icon-arrow-up" /></span>
            </div>
            <div v-if="isMore&&typeArr.length>3" class="option-box unfold-box" @click="isMore=false">
              <span>展开<i class="icon el-icon-arrow-down" /></span>
            </div>
          </div>
          <div class="item">
            <span class="label">贷款期限</span>
            <div class="option-box">
              <span
                :class="['no-interval', maturityLength.limit ? 'active' : '']"
                @click="setMaturityLength('limit')"
              >按额度
                <img
                  v-if="maturityLength.limit"
                  :class="[maturityLength.limit === 2 ? 'spin' : '']"
                  src="@/assets/industryFinance/state_response.png"
                  alt="icon"
                >
                <img
                  v-else
                  src="@/assets/industryFinance/state_default.png"
                  alt="icon"
                >
              </span>
              <span
                :class="['no-interval',maturityLength.rete ? 'active' : '']"
                @click="setMaturityLength('rete')"
              >按利率
                <img
                  v-if="maturityLength.rete"
                  :class="[maturityLength.rete === 2 ? 'spin' : '']"
                  src="@/assets/industryFinance/state_response.png"
                  alt="icon"
                >
                <img
                  v-else
                  src="@/assets/industryFinance/state_default.png"
                  alt="icon"
                >
              </span>
              <span
                :class="['no-interval',maturityLength.brokerage ? 'active' : '']"
                @click="setMaturityLength('brokerage')"
              >按佣金
                <img
                  v-if="maturityLength.brokerage"
                  :class="[maturityLength.brokerage === 2 ? 'spin' : '']"
                  src="@/assets/industryFinance/state_response.png"
                  alt="icon"
                >
                <img
                  v-else
                  src="@/assets/industryFinance/state_default.png"
                  alt="icon"
                >
              </span>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <div class="product-list-box">
          <!-- 单个产品开始 -->
          <div v-for="(item,index) in productArr" :key="index" class="product-single">
            <!-- 产品logo -->
            <img
              class="product-logo"
              :src="item.productLogoUrl.indexOf('https') !== -1 ? item.productLogoUrl : picturePrefix+item.productLogoUrl"
              alt="product-logo"
            >
            <div class="breadth public-class">
              <span class="product-name">{{ item.productName }}</span>
              <p class="describe elp-1">
                {{ item.productDesc }}
              </p>
            </div>
            <div class="width public-class">
              <span>最高额度</span>
              <p class="elp-1">
                {{ item.loanCredit }}万
              </p>
            </div>
            <div class="width public-class">
              <span>贷款期限</span>
              <p class="elp-1">
                {{ item.loanTerm||0 }}个月
              </p>
            </div>
            <div class="width public-class">
              <span>年化率</span>
              <p v-if="item.loanRateLower===item.loanRateUpper" class="color elp-1">
                {{ item.loanRateLower||0 }}%
              </p>
              <p v-else class="color">
                {{ item.loanRateLower||0 }}%-{{ item.loanRateUpper||0 }}%
              </p>
            </div>
            <!-- 申请按钮 -->
            <div class="apply-botton" @click="applyImmediately(item)">
              查看详情
            </div>
          </div>
          <!-- 单个产品结束 -->
          <!-- 没有数据时候的展示 -->
          <NoData v-if="productArr.length===0" class="no-data-box" :type="3" />
          <!-- 分页 -->
          <div class="paging">
            <div class="left">
              共 {{ total }} 项数据
            </div>
            <div class="right">
              <Pagination
                :get-data-list="getdata"
                :params="formInline"
                :total="total"
                :layout-option="'sizes,prev, pager, next'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import NoData from '@/components/NoData.vue'
import { selectAllDict, selectAreaTree, listPage } from '@/http/industryFinance'
export default {
  components: { Pagination, NoData },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
        checkCityCode: '',
        channelCode: '02',
        cityArr: [],
        orderRuleData: {
          orderColumnName: 'productOrder',
          orderRule: 'desc'
        },
        productName: ''
      },
      productArr: [],
      total: 0,
      maturityLength: {
        limit: null,
        rete: null,
        brokerage: null
      }, // 控制贷款期限那些按钮的
      options: [],
      typeArr: [{ dictName: '全部', dictId: '' }],
      activeIndex: 0,
      picturePrefix: '',
      repayType: [],
      isMore: true
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.getdata()
  },
  methods: {
    // 点击立即申请按钮  需要把还款方式的字典和图片前缀传过去
    applyImmediately({ id }) {
      this.$router.push({
        path: '/industryFinance/industryFinanceDetails',
        query: {
          id,
          picturePrefix: this.picturePrefix,
          repayType: JSON.stringify(this.repayType)
        }
      })
    },
    init() {
      selectAllDict(res => {
        const picturePrefix = res.data['fastdfs_http'] || []
        this.repayType = res.data['repay_type'] || []
        if (picturePrefix.length > 0) this.picturePrefix = picturePrefix[0].dictName
        this.typeArr = [{ dictName: '全部', dictId: '' }, ...res.data['product_type']]
      })
      selectAreaTree('', res => {
        res.data.forEach(item => {
          if (item.children?.length > 0) {
            item.children.forEach(val => {
              val.children = null
            })
          }
        })
        this.options = [...res.data]
      })
    },
    // 点击全部清空按钮
    emptyOut() {
      this.activeIndex = 0
      this.maturityLength = {
        limit: null,
        rete: null,
        brokerage: null
      }
      this.formInline = {
        pageSize: 10,
        pageNum: 1,
        checkCityCode: '',
        channelCode: '02',
        cityCode: '',
        cityArr: [],
        orderRuleData: {
          orderColumnName: 'productOrder',
          orderRule: 'desc'
        },
        productName: ''
      }
      this.getdata()
    },
    getdata() {
      listPage(this.formInline, res => {
        this.productArr = [...res.data.records]
        this.total = res.data.total
      })
    },
    // 点击贷款期限
    setMaturityLength(type) {
      switch (type) {
        case 'limit':
          this.maturityLength.rete = null
          this.maturityLength.brokerage = null
          this.formInline.orderRuleData.orderColumnName = 'loanCredit'
          break
        case 'rete':
          this.maturityLength.limit = null
          this.maturityLength.brokerage = null
          this.formInline.orderRuleData.orderColumnName = 'loanRateLower'
          break
        case 'brokerage':
          this.maturityLength.limit = null
          this.maturityLength.rete = null
          this.formInline.orderRuleData.orderColumnName = 'actualRebatesAmount'
          break
      }
      switch (this.maturityLength[type]) {
        case null:
          this.maturityLength[type] = 1
          this.formInline.orderRuleData.orderRule = 'asc'
          break
        case 1:
          this.maturityLength[type] = 2
          this.formInline.orderRuleData.orderRule = 'desc'
          break
        case 2:
          this.maturityLength[type] = 1
          this.formInline.orderRuleData.orderRule = 'asc'
          break
      }
      this.getdata()
    },
    handleChange(arr) {
      if (arr.length === 2) this.formInline.cityCode = arr[1]
      this.getdata()
    },
    // 点击产品类型
    seachProductType(item, index) {
      this.activeIndex = index
      this.formInline.productType = item.dictId
      this.getdata()
    }
  }
}
</script>

<style lang="scss">
:root {
  --primary-theme-color: #D31E0E !important;
}
$themeColors: #D31E0E !important;
.finance-center-contact-information {
  display: flex;
  align-items: center;
  .phone-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    p {
      color: $themeColors;
      font-size: 14px;
      font-weight: 500;
    }
  }
  img {
    height: 32px;
    width: 32px;
    margin-right: 8px;
  }
}
.finance-center-qr-code-box {
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  img {
    display: block;
    height: 146px;
    width: 146px;
    margin-bottom: 8px;
  }
}
.industry-finance-page-box {
  background-color: #f9f9f9;
  .paging {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .right{
      .paginationPageBox{
        padding: 16px 0;
        .el-pagination{
          padding: 0;
        }
      }
    }
    .left {
      color: #666666;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .content-box {
    position: relative;
    .contact-information-box {
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      display: flex;
      flex-direction: column;
      .icon-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 88px;
        width: 72px;
        background-color: #fff;
        border: 1px solid #ededed;
        border-right: 0;
        color: #333333;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        .image {
          display: inline-block;
          height: 40px;
          width: 40px;
        }
        .qr {
          background: url(../../assets/industryFinance/qr.png);
          background-size: 100% 100%;
        }
        .service {
          background: url(../../assets/industryFinance/service.png);
          background-size: 100% 100%;
        }
      }
      .icon-box:last-of-type {
        border-top: 0;
      }
      .icon-box:hover {
        background-color: $themeColors;
        border-color: $themeColors;
        color: #fff;
        .qr {
          background: url(../../assets/industryFinance/qr_hover.png);
          background-size: 100% 100%;
        }
        .service {
          background: url(../../assets/industryFinance/service_hover.png);
          background-size: 100% 100%;
        }
      }
    }
  }
  .center-box {
    transform: translateY(-82px);
    width: $centerPlateWidth;
    margin: auto;

    .product-list-box {
      margin-top: 24px;
      background-color: #fff;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
      padding: 0 40px;
      .no-data-box{
        padding: 36px 0;
      }
      .product-single {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 0;
        border-bottom: 1px solid #ededed;
        .breadth {
          width: 208px;
          .product-name {
            color: #000000;
            font-size: 18px;
            font-weight: 500;
          }
          .describe {
            color: #999;
            font-size: 14px;
            font-weight: 400;
          }
        }
        .public-class {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          height: 52px;
          span {
            color: #999;
            font-size: 14px;
            font-weight: 400;
          }
          p {
            color: #333;
            font-size: 18px;
            font-weight: 500;
          }
          .color {
            color: $themeColors;
            font-size: 20px;
            font-weight: 900;
          }
        }
        .width {
          width: 100px;
        }
        .apply-botton {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 102px;
          height: 38px;
          border-radius: 2px;
          color: $themeColors;
          border: 1px solid $themeColors;
          cursor: pointer;
          transition: all .5s;
        }
        .apply-botton:hover{
          background-color: $themeColors;
          border: 1px solid $themeColors;
          color: #fff!important;
        }
        .product-logo {
          width: 80px;
          height: 80px;
          border-radius: 40px;
        }
      }
      .product-single:last-of-type {
        border-bottom: 0;
      }
    }
    .search-box {
      position: relative;
      padding: 24px;
      background-color: #fff;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
      .emptyButton {
        position: absolute;
        top: 24px;
        right: 24px;
        color: $themeColors;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        z-index: 99;
      }
      .item {
        position: relative;
        display: flex;
        align-items: flex-start;
        margin-bottom: 24px;
        .option-box {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            padding: 0 16px;
            border: 1px solid #ededed;
            border-radius: 4px;
            color: #333;
            font-size: 14px;
            font-weight: 400;
            margin-right: 16px;
            min-width: 72px;
            cursor: pointer;
            margin-bottom: 16px;
            box-sizing: border-box;
            img {
              height: 20px;
              width: 20px;
            }
            .icon{
              font-size: 18px;
              margin-left: 3px;
            }
            .spin {
              transform: rotate(180deg);
            }
          }
          .no-interval{
            margin-bottom: 0;
          }
          .active {
            color: $themeColors;
            border: 1px solid $themeColors;
          }
        }
        .limited-height{
          height: 32px;
          overflow: hidden;
        }
        .unfold-box{
          flex: 0 0 80px;
          span{
            border: 0;
            padding: 0;
          }
        }
        .label {
          flex: 0 0 80px;
          color: #333333;
          font-size: 14px;
          font-weight: 400;
          margin-top: 8px;
        }
        .el-input__prefix{
          left: 288px;
          cursor: pointer;
        }
        .el-input {
          width: 342px;
          height: 36px;
          .el-input__inner {
            border: 1px solid #ededed;
            padding-left: 14px;
            height: 36px;
          }
        }
      }
      .gap{
        margin-bottom: 8px;
      }
      .box-type{
        display: inline-flex;
        margin-right: 40px;
        margin-bottom: 20px;
      }
      .item:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .industry-finance-banner-img {
    width: 100%;
    height: 320px;
  }
}
</style>
